
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  onIonViewDidLeave,
  toastController,
} from '@ionic/vue';
import { defineComponent, inject, onMounted, ref, Ref } from 'vue';
import axios from 'axios';
import MessageBox from '@/components/MessageBox.vue';
import LoadingButton from '@/components/LoadingButton.vue';
import { useRouter } from 'vue-router';
import { throttle } from 'lodash';

export default defineComponent({
  name: 'Confirm',
  components: {
    MessageBox,
    LoadingButton,
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
  },
  setup() {
    const router = useRouter();
    const msgBox: Ref<typeof MessageBox | null> = ref(null);
    const confirmationCodeText = ref('');
    const getUsername: () => Ref<string> = inject('getUsername', () => ref(''));
    const getUserEmail: () => Ref<string> = inject('getUserEmail', () => ref(''));
    const setConfirmationNeeded: (confirmationNeeded: boolean) => void = inject(
      'setConfirmationNeeded',
      () => undefined,
    );
    const confirmButton: Ref<typeof LoadingButton | null> = ref(null);

    onIonViewDidLeave(() => {
      msgBox.value?.close();
      confirmationCodeText.value = '';
      setConfirmationNeeded(false);
    });

    const isValidConfirmationCode = (code: string): boolean => {
      if (code.length !== 6) {
        return false;
      }
      // Checks if every character is a digit
      for (let i = 0; i < code.length; i++) {
        if (code.charAt(i) < '0' || code.charAt(i) > '9') {
          return false;
        }
      }
      return true;
    };

    onMounted(() => {
      msgBox.value?.setColor('medium');
      msgBox.value?.showMsg(`A confirmation code has been sent to ${getUserEmail().value}`);
    });

    const resendConfirmationCode = throttle(async (isExpired: boolean): Promise<void> => {
      msgBox.value?.close();
      try {
        const {
          data: { Message },
        } = await axios.post(process.env.VUE_APP_USER_ENDPOINT_URL + '/v1/user/resendCode', {
          name: getUsername().value,
        });
        if (Message === 'Resend code success') {
          if (isExpired) {
            msgBox.value?.showMsg(
              `Confirmation code has expired. It has been resent to ${getUserEmail().value}`,
            );
          } else {
            msgBox.value?.showMsg(`A confirmation code has been sent to ${getUserEmail().value}`);
          }
        }
      } catch (error:any) {
        // This should never occur because only successful signup and unconfirmed login will route to this page
        if (error.response.data.Message === 'UserNotFoundException') {
          msgBox.value?.showMsg(`Account not found, please sign up!`);
        } else {
          msgBox.value?.showMsg('Error: ' + error.response.data.Message);
        }
      }
    }, 1000);

    const onSubmit = throttle(async (): Promise<boolean> => {
      msgBox.value?.close();
      msgBox.value?.setColor('danger');

      confirmationCodeText.value = confirmationCodeText.value.trim();

      if (!isValidConfirmationCode(confirmationCodeText.value)) {
        msgBox.value?.showMsg('Confirmation code must be 6 digits');
        return false;
      }

      confirmButton.value?.setIsLoading(true);
      try {
        const response = await axios.post(
          process.env.VUE_APP_USER_ENDPOINT_URL + '/v1/user/confirm',
          {
            name: getUsername().value,
            code: confirmationCodeText.value,
          },
        );
        if (response.data.Message === 'Confirmation success') {
          setConfirmationNeeded(false);

          const toast = await toastController.create({
            header: 'Confirmation successful, please log in',
            position: 'bottom',
            color: 'success',
            duration: 3000,
            buttons: [
              {
                text: 'Close',
                role: 'cancel',
              },
            ],
          });
          toast.present();

          router.push({ name: 'Login' });
        } else {
          msgBox.value?.showMsg('Unable to verify: ' + response.data.Message);
        }
      } catch (error:any) {
        if (error.response) {
          if (error.response.data.Message === 'CodeMismatchException') {
            msgBox.value?.showMsg('Wrong confirmation code');
          } else if (error.response.data.Message === 'ExpiredCodeException') {
            resendConfirmationCode(true);
          } else {
            msgBox.value?.showMsg('Error: ' + error.response.data.Message);
          }
        } else if (error.request) {
          msgBox.value?.showMsg('Bad request');
        } else {
          msgBox.value?.showMsg('Error: ' + error.message);
        }
      } finally {
        confirmButton.value?.setIsLoading(false);
      }

      return true;
    }, 1000);

    return {
      onSubmit,
      msgBox,
      confirmationCodeText,
      resendConfirmationCode,
      confirmButton,
    };
  },
});
